import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
// import "antd/dist/antd.css";
import FormContext  from './Contexts/FormContext';

import 'antd/dist/antd.min.css'
import Router from "./router";
import i18n from "./translation";
import "./global.css";
import { useState } from "react";
import { Provider } from 'react-redux'
import { store } from './redux/store';

interface FormContextValue {
  upload: boolean;
  setUpload: React.Dispatch<React.SetStateAction<boolean>>;
}


const App = () => {

  const [upload, setUpload] = useState<boolean>(false);

  return (
  <FormContext.Provider value={{ upload, setUpload }}>
  <Provider store={store}>
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
  </Provider>
  </FormContext.Provider>
);
}

ReactDOM.render(<App />, document.getElementById("root"));
