import React, { useEffect, useState } from "react"

import { styles } from "../styles";

import EmailForm from "./EmailForm";
import ChatEngine from "./ChatEngine";
import MessagePage from "../../../../components/ChatExtension/MessagePage";
import { logout, setOnlineUser, setSocketConnection, setUser } from "../../../../redux/userSlice";
import { useDispatch, useSelector } from 'react-redux'
import io from 'socket.io-client'
import axios from "axios";

const SupportWindow = props => {
    const [user, setUser] = useState(null)
    const [chat, setChat] = useState(null)

    const dispatch = useDispatch()

    // console.log('user',user)
  const fetchUserDetails = async()=>{
    try {
        const URL = `${process.env.REACT_APP_BACKEND_URL}/api/user-details`
        const token = localStorage.getItem('support_token');

        if(token){
        const response = await axios.get(URL, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
      
        dispatch(setUser(response.data.data))

        if(response.data.data.logout){
            dispatch(logout())
            // navigate("/email")
        }
        console.log("current user Details",response)
      }
    } catch (error) {
        console.log("error",error)
        // navigate("/email")
    }
  }

  useEffect(()=>{
    fetchUserDetails()
  },[])
    
    useEffect(()=>{
        if(user){
        const socketConnection = io(process.env.REACT_APP_BACKEND_URL,{
          auth : {
            token : localStorage.getItem('support_token')
          },
        })
    
        socketConnection.on('onlineUser',(data)=>{
          console.log("nnnnnnn", data)
          dispatch(setOnlineUser(data))
        })
    
        dispatch(setSocketConnection(socketConnection))
    
        return ()=>{
          socketConnection.disconnect()
        }
    }
      },[user])

    return (
        <div 
            className='transition-5'
            style={{
                ...styles.supportWindow,
                ...{ opacity: props.visible ? '1' : '0' },
                zIndex: 1000,
            }}
        >
            <EmailForm 
                visible={user === null }
                setUser={user => setUser(user)} 
            />

            {/* <ChatEngine 
                visible={user !== null || chat !== null}
                user={user} 
                chat={chat} 
            /> */}

            {/* <div>
                get in
            </div> */}

            {/* {user && <MessagePage   userId="66c7ab6dcddd0d2fe5eb8332" user={user} /> } */}
            {user && <MessagePage   userId="66ce399b09826914d9bb85a2" user={user} /> }
        </div>
    )
}

export default SupportWindow;
