import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json";
import translationEs from "./locales/es/translation.json";


function storeLanguage(lng: string) {
  try {
    localStorage.setItem('i18nextLng', lng);
  } catch (error) {
    console.error('Error storing language:', error);
  }
}

function getStoredLanguage() {
  try {
    return localStorage.getItem('i18nextLng');
  } catch (error) {
    console.error('Error retrieving stored language:', error);
    return null;
  }
}


i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: getStoredLanguage() || 'en',
    fallbackLng: "en",

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEn,
      },
      es: {
        translations: translationEs,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;